<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="date_from">Date From</label>
            <b-input
              id="date_from"
              v-model="filter.date_from"
              type="date"
              @change="onFilterChanged"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="date_from">Date To</label>
            <b-input
              id="date_from"
              v-model="filter.date_to"
              type="date"
              @change="onFilterChanged"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :items="myProvider"
        :fields="tableSettings.fields"
        show-empty
      >
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(channel_facebook)="data">
          {{ parseInt(data.item.channel_facebook_one) + parseInt(data.item.channel_facebook_two) }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>
      </b-table>

      <!-- <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row> -->
    </b-card>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { SupervisorReportService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'SupervisorFarmerPromotionSummaries',

  middleware: ['auth', 'supervisor'],

  metaInfo () {
    return {
      title: 'Farmer Reports - Promotion Summaries'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        busy: false
      },
      filter: {
        date_from: this.dateFormatter(Date.now()),
        date_to: this.dateFormatter(Date.now())
      },
      tableSettings: {
        fields: [
          'index',
          { key: 'promotion_name', label: 'promotion' },
          { key: 'channel_call', label: 'call' },
          { key: 'channel_facebook', label: 'facebook' },
          { key: 'channel_sms', label: 'sms' }
        ]
      }
    }
  },

  mounted () {
    core.index()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await SupervisorReportService.getFarmerPromotionSummaries(
          this.objectToUrl({
            filter_from: this.filter.date_from,
            filter_to: this.filter.date_to
          })
        )
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    onFilterChanged () {
      this.$refs.table.refresh()
    }
  }
}
</script>
